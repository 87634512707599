import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  adaptive: {
    id: 'app.Calendar.CreateEvent.adaptive',
    defaultMessage: 'Smart Availability Management',
  },
  adaptiveDescription: {
    id: 'app.Calendar.CreateEvent.adaptiveDescription',
    defaultMessage:
      'Automatically detects "busy" events on Host’s connected calendars and sessions already booked by applicants. Conflicting sessions are hidden automatically and will reappear if the Host becomes available again.',
  },
  addNewLocation: {
    id: 'app.Calendar.CreateEvent.addNewLocation',
    defaultMessage: '+ Add new location',
  },
  allowMultipleAttendees: {
    id: 'app.Calendar.CreateEvent.allowMultipleAttendees',
    defaultMessage: 'Allow multiple people to attend the event.',
  },
  cancel: {
    id: 'app.Calendar.CreateEvent.cancel',
    defaultMessage: 'Cancel',
  },
  changesFailedToSave: {
    id: 'app.Calendar.CreateEvent.changesFailedToSave',
    defaultMessage: 'Changes couldn’t be saved. Please review the errors.',
  },
  conditionSelections: {
    id: 'app.Calendar.CreateEvent.conditionSelections',
    defaultMessage: 'Define Condition',
  },
  conditionSelectorDescription: {
    id: 'app.Calendar.CreateEvent.conditionSelectorDescription',
    defaultMessage:
      'The selectable values below are based on the Host’s Openings access, which may not encompass all Openings tied to a Location Group or Scheduler stage. <learnMoreLink>Learn more</learnMoreLink> about building conditions.',
  },
  conditionType: {
    id: 'app.Calendar.CreateEvent.conditionType',
    defaultMessage: 'Condition Type',
  },
  conditionTypeTooltip: {
    id: 'app.Calendar.CreateEvent.conditionTypeTooltip',
    defaultMessage:
      '“Opening" condition type will include all Scheduler stages within the selected Opening(s) unless a “Stage Name” is specified as a condition.',
  },
  createEvent: {
    id: 'app.Calendar.CreateEvent.createEvent',
    defaultMessage: 'Add availability',
  },
  date: {
    id: 'app.Calendar.CreateEvent.date',
    defaultMessage: 'Date',
  },
  deleteSeries: {
    id: 'app.Calendar.CreateEvent.deleteSeries',
    defaultMessage: 'Delete series',
  },
  deleteSeriesConfirmation: {
    id: 'app.Calendar.CreateEvent.deleteSeriesConfirmation',
    defaultMessage:
      'Are you sure you want to delete this series? All booked sessions will remain unchanged.',
  },
  deleteSession: {
    id: 'app.Calendar.CreateEvent.deleteSession',
    defaultMessage: 'Delete session',
  },
  deleteSessionConfirmation: {
    id: 'app.Calendar.CreateEvent.deleteSessionConfirmation',
    defaultMessage:
      'Are you sure you want to delete this session? All booking(s) for this session will be cancelled.',
  },
  deleteSessionSuccess: {
    id: 'app.Calendar.CreateEvent.deleteSessionSuccess',
    defaultMessage:
      '{count, plural, one {# session} other {# sessions}} successfully deleted.',
  },
  endRepeatAfter: {
    id: 'app.Calendar.CreateEvent.endRepeatAfter',
    defaultMessage: 'End repeat after',
  },
  endTime: {
    id: 'app.Calendar.CreateEvent.endTime',
    defaultMessage: 'End time',
  },
  eject: {
    id: 'app.Calendar.CrateEvent.eject',
    defaultMessage:
      'Editing this session will only impact this occurrence and remove it from its current series.',
  },
  eventCapacity: {
    id: 'app.Calendar.CreateEvent.eventCapacity',
    defaultMessage: 'Host and capacity',
  },
  eventCapacityDescription: {
    id: 'app.Calendar.CreateEvent.eventCapacityDescription',
    defaultMessage:
      'Choose a host for the event and set the maximum number of attendees per session.',
  },
  eventCreateFailed: {
    id: 'app.Calendar.CreateEvent.eventCreateFailed',
    defaultMessage: 'No Stage matches the current selected condition',
  },
  eventCreateSuccess: {
    id: 'app.Calendar.CreateEvent.eventCreateSuccess',
    defaultMessage: 'Session created successfully',
  },
  eventCreateSuccessfullyWithConflict: {
    id: 'app.Calendar.CreateEvent.eventCreateWithConflict',
    defaultMessage: 'Session created successfully, but hidden due to conflict.',
  },
  eventDeleteFailed: {
    id: 'app.Calendar.CreateEvent.eventDeleteFailed',
    defaultMessage: 'Event Deleted Failed',
  },
  eventDetails: {
    id: 'app.Calendar.CreateEvent.eventDetails',
    defaultMessage: 'Event details',
  },
  eventInstructions: {
    id: 'app.Calendar.CreateEvent.eventInstructions',
    defaultMessage: 'Event instructions',
  },
  eventName: {
    id: 'app.Calendar.CreateEvent.eventName',
    defaultMessage: 'Event name',
  },
  eventNameInstruction: {
    id: 'app.Calendar.CreateEvent.eventNameInstruction',
    defaultMessage:
      'Enter a name used on the calendar and in the applicant portal.',
  },
  eventTimeAndSeries: {
    id: 'app.Calendar.CreateEvent.eventTimeAndSeries',
    defaultMessage: 'Time and recurrence',
  },
  eventTimeAndSeriesDescription: {
    id: 'app.Calendar.CreateEvent.eventTimeAndSeriesDescription',
    defaultMessage:
      'Set the date and time for a single session. To create a series with multiple sessions, use the Repeat or Split options.',
  },
  eventPlaceholder: {
    id: 'app.Calendar.CreateEvent.eventPlaceholder',
    defaultMessage:
      'e.g. In-Person Interview, Phone Interview, Orientation, Hiring Event',
  },
  frequencyOptions: {
    id: 'app.Calendar.CreateEvent.frequencyOptions',
    defaultMessage: '{count, plural, one {# Occurrence} other {# Occurrences}}',
  },
  header: {
    id: 'app.Calendar.CreateEvent.header',
    defaultMessage: 'Create event',
  },
  host: {
    id: 'app.Calendar.CreateEvent.host',
    defaultMessage: 'Host',
  },
  instructionsPlaceholder: {
    id: 'app.Calendar.CreateEvent.instructionsPlaceholder',
    defaultMessage:
      'e.g. Please have your phone nearby at the scheduled interview time, as a recruiter will be reaching out to you.',
  },
  location: {
    id: 'app.Calendar.CreateEvent.location',
    defaultMessage: 'Location',
  },
  locationDetails: {
    id: 'app.Calendar.CreateEvent.locationDetails',
    defaultMessage: 'Location details',
  },
  locationDetailsDescription: {
    id: 'app.Calendar.CreateEvent.locationDetailsDescription',
    defaultMessage:
      'Write a brief instructions or details of the event location.',
  },
  locationPlaceholder: {
    id: 'app.Calendar.CreateEvent.locationPlaceholder',
    defaultMessage: 'e.g. 777 Apple Street, San Francisco, CA 95932',
  },
  maxAttendees: {
    id: 'app.Calendar.CreateEvent.maxAttendees',
    defaultMessage: 'Maximum number of attendees',
  },
  maxAttendeesTooltip: {
    id: 'app.Calendar.CreateEvent.maxAttendeesTooltip',
    defaultMessage:
      'This number of attendees will determine how many slots are available for this session.',
  },
  none: {
    id: 'app.Calendar.CreateEvent.none',
    defaultMessage: 'None',
  },
  noSplit: {
    id: 'app.Calendar.noSplit',
    defaultMessage: 'No split',
  },
  openingStage: {
    id: 'app.Calendar.CreateEvent.openingStage',
    defaultMessage: 'Opening stage',
  },
  repeat: {
    id: 'app.Calendar.CreateEvent.repeat',
    defaultMessage: 'Repeat',
  },
  requiredField: {
    id: 'app.Calendar.CreateEvent.requiredField',
    defaultMessage: 'Required Field',
  },
  save: {
    id: 'app.Calendar.CreateEvent.save',
    defaultMessage: 'Save',
  },
  scheduleStageSelector: {
    id: 'app.Calendar.CreateEvent.scheduleStageSelector',
    defaultMessage: 'Scheduler stage',
  },
  selectConditions: {
    id: 'app.Calendar.CreateEvent.selectConditions',
    defaultMessage: 'Selection conditions',
  },
  seriesCreateSuccess: {
    id: 'app.Calendar.CreateEvent.seriesCreateSuccess',
    defaultMessage: '{count} sessions successfully created.',
  },
  seriesCreateSuccessfullyWithConflict: {
    id: 'app.Calendar.CreateEvent.seriesCreateSuccessfullyWithConflict',
    defaultMessage:
      '{count} sessions successfully created. Some hidden due to conflict.',
  },
  seriesDeleteSuccess: {
    id: 'app.Calendar.CreateEvent.seriesDeleteSuccess',
    defaultMessage: 'Availability successfully deleted',
  },
  seriesEditedFailed: {
    id: 'app.Calendar.CreateEvent.seriesEditedFailed',
    defaultMessage: 'No Stage matches the current selected condition',
  },
  seriesEditedSuccess: {
    id: 'app.Calendar.CreateEvent.seriesEditedSuccess',
    defaultMessage: '{count} sessions updated successfully.',
  },
  seriesEditedSuccessfullyWithConflict: {
    id: 'app.Calendar.CreateEvent.seriesEditedSuccessfullyWithConflict',
    defaultMessage:
      '{count} sessions updated successfully. Some hidden due to conflict.',
  },
  sessionDeleteSuccess: {
    id: 'app.Calendar.CreateEvent.sessionDeleteSuccess',
    defaultMessage: 'Session successfully deleted',
  },
  sessionEditedFailed: {
    id: 'app.Calendar.CreateEvent.sessionEditedFailed',
    defaultMessage: 'No Stage matches the current selected condition',
  },
  sessionEditedSuccess: {
    id: 'app.Calendar.CreateEvent.sessionEditedSuccess',
    defaultMessage: 'Session Edited Successfully',
  },
  sessionEditedSuccessfullyWithConflict: {
    id: 'app.Calendar.CreateEvent.sessionEditedSuccessfullyWithConflict',
    defaultMessage: 'Session successfully edited, but hidden due to conflict.',
  },
  setEventName: {
    id: 'app.Calendar.CreateEvent.setEventName',
    defaultMessage: 'Set event name',
  },
  setLocationDescription: {
    id: 'app.Calendar.CreateEvent.setLocationDescription',
    defaultMessage: 'Set the location where your event will take place.',
  },
  splitInto: {
    id: 'app.Calendar.CreateEvent.splitInto',
    defaultMessage: 'Split into',
  },
  splitIntoSmaller: {
    id: 'app.Calendar.CreateEvent.splitIntoSmaller',
    defaultMessage: 'Split into smaller sessions',
  },
  stageSelectorDescription: {
    id: 'app.Calendar.CreateEvent.stageSelectorDescription',
    defaultMessage:
      'Make your availability bookable by applicants in specific Scheduler stages. Any future Scheduler stages that match your conditions will automatically be included.',
  },
  startTime: {
    id: 'app.Calendar.CreateEvent.startTime',
    defaultMessage: 'Start time',
  },
  subTitle: {
    id: 'app.Calendar.CreateEvent.subTitle',
    defaultMessage:
      'Add your availability to the event to allow applicants to schedule bookings.',
  },
  time: {
    id: 'app.Calendar.CreateEvent.time',
    defaultMessage: 'Time',
  },
  timeErrorMessage: {
    id: 'app.Calendar.CreateEvent.timeErrorMessage',
    defaultMessage: 'Must be in the future',
  },
  unavailableSplitShort: {
    id: 'app.Calendar.CreateEvent.unavailableSplit',
    defaultMessage: 'Unavailable for sessions 15 minutes or shorter',
  },
  unavailableSplitDisabled: {
    id: 'app.Calendar.CreateEvent.unavailableDisabled',
    defaultMessage: 'Editing disabled for saved sessions',
  },
  updateEvent: {
    id: 'app.Calendar.CreateEvent.updateEvent',
    defaultMessage: 'Edit session',
  },
  updateSeries: {
    id: 'app.Calendar.CreateEvent.updateSeries',
    defaultMessage: 'Edit series',
  },
  value: {
    id: 'app.Calendar.CreateEvent.value',
    defaultMessage: 'Value(s)',
  },
  isAnyOf: {
    id: 'app.Calendar.CreateEvent.isAnyOf',
    defaultMessage: 'is any of',
  },
});
